import {ApolloError, gql, QueryLazyOptions, useLazyQuery} from "@apollo/client";
import {ViewerQuery, ViewerQueryVariables} from "./useLoadViewer.generated";
import React, {useEffect} from "react";

export type UseLoadViewerResult = {
    viewer: ViewerQuery['viewer'] | null;
    error: ApolloError | undefined;
    isLoading: boolean;
    isError: boolean;
    isSuccess: boolean;
    setViewer: (viewer: ViewerQuery['viewer'] | null) => void;
    load: (options?: QueryLazyOptions<ViewerQueryVariables>) => void,
    internalState: {
        data: ViewerQuery | undefined;
        called: boolean;
        loading: boolean;
        error: ApolloError | undefined;
        viewer: ViewerQuery['viewer'] | null;
    }
};

export function useLoadViewer(isLoggedIn: boolean): UseLoadViewerResult {
    const [loadViewer, {data, loading, error, called}] = useLazyQuery<ViewerQuery, ViewerQueryVariables>(GET_VIEWER, {
        fetchPolicy: "network-only",
    });
    const viewer = data?.viewer ?? null;
    const [state, setState] = React.useState({data, viewer, loading, error, called});

    useEffect(() => {
        isLoggedIn && loadViewer();
    }, [isLoggedIn, loadViewer]);

    useEffect(() => {
        setState({data, viewer: data?.viewer ?? null, loading, error, called});
    }, [data, viewer, loading, error, called]);

    const setViewer = React.useCallback((viewer: ViewerQuery['viewer'] | null) => setState(s => ({...s, viewer})), [
        setState,
    ]);

    return {
        error: state.error,
        viewer: state.viewer,
        isLoading: state.loading,
        isError: !!state.error,
        isSuccess: !state.called || !!state.data,
        setViewer,
        load: loadViewer,
        internalState: state,
    };
}

const GET_VIEWER = gql`
    query Viewer {
        viewer {
            id
            name
            email
            isAdmin
            isReporter
        }
    }
`;
