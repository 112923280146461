import React from 'react';
import {useApolloClient} from "@apollo/client";
import SpinnerBox from "../components/SpinnerBox";
import {useLoadViewer, UseLoadViewerResult} from "./useLoadViewer";
import * as authClient from './auth-client'
import {getToken, clearToken} from './token';

function isLoggedIn() {
  return Boolean(getToken());
}

type AuthContextValue = {
  logout: () => void;
  login: (data: { username: string; password: string }) => Promise<any>;
  activate: (data: { token: string; password: string }) => Promise<any>;
  requestPasswordReset: (data: { email: string }) => Promise<any>;
  resetPassword: (data: { token: string; password: string }) => Promise<any>;
  viewer: UseLoadViewerResult['viewer'];
};

const AuthContext = React.createContext<AuthContextValue | undefined>(undefined);
AuthContext.displayName = 'AuthContext';

type AuthProviderProps = {
  children: React.ReactNode,
};

function AuthProvider(props: AuthProviderProps) {
  const client = useApolloClient();
  const {
    error,
    viewer,
    isLoading,
    isError,
    isSuccess,
    setViewer,
    load,
    internalState
  } = useLoadViewer(isLoggedIn());

  const login = React.useCallback((data: { username: string; password: string }) => {
    return authClient.login(data).then(() => load());
  }, [load]);

  const activate = React.useCallback((data: { token: string; password: string }) => {
    return authClient.activate(data).then(() => load());
  }, [load]);

  const logout = React.useCallback(async () => {
    clearToken();
    client.clearStore();
    setViewer(null);
  }, [client, setViewer]);

  const requestPasswordReset = authClient.requestPasswordReset;

  const resetPassword = React.useCallback((data: { token: string; password: string }) => {
    return authClient.resetPassword(data).then(() => load());
  }, [load]);

  const value = React.useMemo(() => ({viewer, login, logout, activate, requestPasswordReset, resetPassword}), [
    login,
    logout,
    viewer,
    activate,
    requestPasswordReset,
    resetPassword,
  ]);

  if (isLoading) {
    return <SpinnerBox height="100vh"/>;
  }

  if (isError) {
    // TODO: an error component
    return (
      <div
        style={{
          color: 'red',
          height: '100vh',
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'center',
          alignItems: 'center',
        }}
      >
        <p>Uh oh... There's a problem. Try refreshing the app.</p>
        <pre>{error?.message}</pre>
      </div>
    );
  }

  if (isSuccess) {
    return <AuthContext.Provider value={value} {...props} />;
  }

  throw new Error(`Unhandled state: ${JSON.stringify(internalState)}`)
}

function useAuth() {
  const context = React.useContext(AuthContext);
  if (context === undefined) {
    throw new Error(`useAuth must be used within a AuthProvider`)
  }
  return context;
}

export {AuthProvider, useAuth}
