import {setToken} from './token';

// TODO: auth endpoint REACT_APP_API_AUTH_URL
const apiURL: string = process.env.REACT_APP_API_GRAPHQL_URL!.replace('/graphql', '/auth');

type ClientInit = {
  data?: any;
} & RequestInit;

async function client(
  endpoint: string,
  {data, headers: customHeaders, ...customConfig}: ClientInit = {},
) {
  const headers = data
    ? {'Content-Type': 'application/json', ...customHeaders}
    : customHeaders
  ;
  const config = {
    method: data ? 'POST' : 'GET',
    body: data ? JSON.stringify(data) : undefined,
    headers,
    ...customConfig,
  }

  return fetch(`${apiURL}/${endpoint}`, config).then(async response => {
    const data = await response.json()
    if (response.ok) {
      return data
    } else {
      return Promise.reject(data)
    }
  })
}

export function login({username, password}: { username: string; password: string }) {
  return client('login', {data: {username, password}}).then(handleLoginResponse)
}

export function activate({token, password}: { token: string; password: string }) {
  return client('activate', {data: {token, password}}).then(handleLoginResponse)
}

export function requestPasswordReset({email}: { email: string }) {
  return client('request-password-reset', {data: {email}});
}

export function resetPassword({token, password}: { token: string; password: string }) {
  return client('reset-password', {data: {token, password}}).then(handleLoginResponse)
}

function handleLoginResponse({token}: { token: string }) {
  setToken(token);
}
